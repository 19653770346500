
<template>
  <v-container fluid>
    <TeacherCertificates/>
  </v-container>
</template>

<script>


export default {
  name: 'TeacherCertificateList',
  components: {
    TeacherCertificates: () => import('@/components/course_certificate_list/TeacherCertificates.vue')
  },
}
</script>

<style scoped>

</style>